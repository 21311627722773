/* Auto-generated file created by dtovbeinJC 08/11/2018 at 18:16:03hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.title-view) {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  height: 100%;
}

:local(.title-view) :local(h1) {
  color: #FF7F00;
  font-family: "geograph-bold";
  font-size: 2.5rem;
  line-height: 100%;
}

:local(.title-view) :local(.miscellany) {
  align-self: center;
  background: linear-gradient(left, #FFBC00, #FF7F00);
  display: inline-flex;
  margin-left: 0;
  height: .2rem;
  width: 5.75rem;
}