/* Auto-generated file created by Dan Tovbein 15/02/2019 at 18:58:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.wrapper-link) a {
  color: #fff; }

:local(.title) {
  display: inline-flex;
  width: 100%;
  color: #fff;
  font-size: .9rem;
  margin: .2rem 0; }
