/* Auto-generated file created by Dan Tovbein 21/12/2018 at 14:51:49hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.tab-section) {
  border-top: solid 1px #231F20;
  margin-top: 1rem;
  padding-top: 1rem; }
  :local(.tab-section) :local(.plain-text) {
    color: #373435; }
    :local(.tab-section) :local(.plain-text) ul {
      padding-top: 1rem;
      padding-left: 1rem;
      list-style-type: disc; }
      :local(.tab-section) :local(.plain-text) ul li {
        font-size: .85rem;
        padding-bottom: .4rem; }
