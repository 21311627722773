/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:32:54hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-content) {
  display: flex;
  padding: 3.2rem 10vw;
  width: 100%;
  height: 100%;
  flex-direction: column; }
  :local(.view-content) .title-view {
    width: 100%;
    padding: 3rem 0;
    height: auto; }
  :local(.view-content) :local(.wrapper) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; }
  :local(.view-content).mobile, :local(.view-content).desktop.extra-small, :local(.view-content).desktop.small, :local(.view-content).desktop.medium {
    padding-left: 5vw !important;
    padding-right: 5vw !important; }
    :local(.view-content).mobile .title-view, :local(.view-content).desktop.extra-small .title-view, :local(.view-content).desktop.small .title-view, :local(.view-content).desktop.medium .title-view {
      padding: 1rem 0; }
    :local(.view-content).mobile :local(.wrapper), :local(.view-content).desktop.extra-small :local(.wrapper), :local(.view-content).desktop.small :local(.wrapper), :local(.view-content).desktop.medium :local(.wrapper) {
      flex-direction: column; }
