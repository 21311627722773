/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:46hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-wrapper-info-title) {
  color: #231F20;
  font-family: "geograph-bold";
  font-size: 1rem;
  line-height: 120%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  margin: .25rem 0; }
