/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:14:12hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.home) {
  align-items: center;
  background-attachment: fixed;
  background-blend-mode: multiply;
  background-color: rgba(39, 38, 34, 0.25);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 0 0 0 !important;
}

:local(.home) .degraded-line {
  margin: 1.5rem auto 0 auto;
}

:local(.home).desktop.medium,
:local(.home).desktop.large {
  height: 150%;
}

:local(.wrapper) {
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 70%;
  padding-top: 5%;
}

:local(.wrapper) :local(.title) {
  font-family: "geograph-medium";
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;
}

:local(.wrapper) :local(p) {
  font-family: "geograph-regular-italic";
  font-size: 1rem;
  line-height: 200%;
  text-align: center;
}

:local(.wrapper) :local(p) :local(.highlighted) {
  font-family: "geograph-bold-italic";
}

:local(.wrapper).mobile,
:local(.wrapper).desktop.small,
:local(.wrapper).desktop.extra-small {
  width: 85%;
  padding-top: 0;
  padding-bottom: 10rem;
}

:local(.wrapper).mobile :local(.title),
:local(.wrapper).desktop.small :local(.title),
:local(.wrapper).desktop.extra-small :local(.title) {
  width: 100%;
  margin: 0 auto;
  font-size: 2rem;
}

:local(.footer) {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-family: "geograph-regular";
  font-size: .85rem;
  text-align: center;
  padding: 1rem 0;
  width: 100%;
}