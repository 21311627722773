/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:11:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.main-header) {
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  display: flex;
  height: 7rem;
  position: fixed;
  top: 0;
  transition: all .2s ease;
  width: 100%;
  border-bottom: solid 9px linear-gradient(top, #606c88, #3f4c6b);
  z-index: 99999;
}

:local(.main-header) :local(.wrapper-logo) {
  display: flex;
  padding-left: 2rem;
  padding-right: 1rem;
  flex: 0 0 25%;
}

:local(.main-header) :local(.wrapper-logo) :local(.logo) {
  width: fit-content;
}

:local(.main-header) :local(.wrapper-logo).mobile {
  width: 80%;
  padding: 0 1rem;
}

:local(.main-header) :local(.wrapper-logo).mobile :local(.logo) {
  width: 70vw;
  height: 100%;
}

:local(.main-header) :local(.wrapper-logo).mobile.landscape {
  height: 100%;
}

:local(.main-header) :local(.wrapper-logo).mobile.landscape :local(.logo) {
  width: auto;
}

:local(.main-header) :local(.navs) {
  display: none;
  height: 100%;
  flex: 1 1 100%;
  justify-content: flex-end;
  padding-right: 2rem;
}

:local(.main-header) :local(.navs) :local(.main-nav) {
  display: flex;
  flex-direction: row;
}

:local(.main-header) :local(.navs) :local(.social-nav) {
  display: flex;
  align-items: center;
}

:local(.main-header) :local(.navs) :local(.social-nav) .button-rounded {
  background-color: #FFBC00 !important;
  margin: 0 1rem;
  text-align: center;
}

:local(.main-header) :local(.navs) :local(.social-nav) :local(.facebook-icon) {
  height: 2rem;
  width: 2rem;
}

:local(.facebook-icon) {
  margin-right: .2rem;
}

:local(.main-header) :local(.navs).desktop.large {
  display: flex;
}

:local(.main-header) :local(.btn-burger) {
  align-self: flex-end;
  height: 1.6rem;
  width: auto;
  display: none;
  padding: 0 1.5rem 0 0;
  margin-bottom: auto;
  margin-top: auto;
}

:local(.main-header) :local(.btn-burger).mobile,
:local(.main-header) :local(.btn-burger).desktop.extra-small,
:local(.main-header) :local(.btn-burger).desktop.small,
:local(.main-header) :local(.btn-burger).desktop.medium {
  display: flex;
}

:local(.main-header):local(.is-small) {
  background-color: rgba(255, 255, 255, 0.98);
  height: 5rem;
}

:local(.main-header).mobile :local(.btn-burger) {
  height: 2rem;
}

:local(.border-bottom) {
  position: absolute;
  bottom: -9px;
  left: 0;
  display: flex;
  height: 9px;
  width: 100%;
  align-self: flex-end;
  background: linear-gradient(90deg, #FFBC00 0%, #FFBC00 70%, #FF7F00 100%);
  z-index: -1;
}