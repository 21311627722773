/* Auto-generated file created by Dan Tovbein 23/03/2019 at 20:15:05hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.arrow-scroll-top) {
  height: 4rem;
  position: fixed;
  z-index: 1;
  animation: moveScrollTopArrow 2s infinite;
  transition: right 1s ease;
  right: 0; }

@keyframes moveScrollTopArrow {
  0% {
    top: 70vh; }
  50% {
    top: 70.5vh; }
  100% {
    top: 70vh; } }
