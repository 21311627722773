/* Auto-generated file created by Dan Tovbein 20/12/2018 at 21:51:45hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-details-wrapper) {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: scroll; }
