/* Auto-generated file created by dtovbeinJC 03/12/2018 at 20:38:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.portal) {
  align-items: center;
  background-color: #EEEFF0;
  display: flex;
  padding: 3.2rem 10vw;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  overflow-y: scroll;
}

:local(.portal) :local(.wrapper) {
  opacity: 0;
  height: 0;
  transition: all .2s ease;
  width: 100%;
}

:local(.wrapper) {
  margin-top: 200px;
}

:local(.portal) :local(.btn-close) {
  cursor: pointer;
  font-size: 1.05rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #FF7F00;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform .5s ease;
  transform: scale(1);
}

:local(.portal) :local(.btn-close):hover {
  transform: scale(0.9);
}

:local(.portal).desktop.extra-small,
:local(.portal).desktop.small,
:local(.portal).desktop.medium,
:local(.portal).mobile {
  padding: 2rem 8vw 1rem 8vw;
  overflow: scroll;
}

:local(.portal).desktop.extra-small :local(.btn-close),
:local(.portal).desktop.small :local(.btn-close),
:local(.portal).desktop.medium :local(.btn-close),
:local(.portal).mobile :local(.btn-close) {
  top: 0;
}