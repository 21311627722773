/* Auto-generated file created by dtovbeinJC 05/11/2018 at 16:51:54hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item) {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  transition: all .2s ease;
  padding: 1.1rem;
}

:local(.view-slider-item):hover {
  background-color: rgba(255, 255, 255, 0.6);
}

:local(.course-item) {
  width: 100%;
  height: 100%;
}

:local(.course-item) .view-slider-item-wrapper-image {
  height: 40%;
}

:local(.course-item) .view-slider-item-wrapper-image .image {
  width: 100%;
  height: 100%;
}

:local(.course-item) .view-slider-item-wrapper-info {
  height: 60%;
}

:local(.course-item) .view-slider-item-wrapper-info-date {
  margin-top: .4rem;
  color: #FF7F00;
}

:local(.footer) {
  margin-top: auto;
}

:local(.btnVerMas) {
  background-color: #FF7F00;
  border-radius: .75rem;
  color: white;
  font-size: .825rem;
  padding: .5rem 1rem;
  cursor: pointer;
}