/* Auto-generated file created by Dan Tovbein 20/12/2018 at 16:04:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-details-header-tabs-tab) {
  cursor: pointer;
  display: inline-flex;
  font-size: .9rem;
  margin: 0 1rem;
  justify-content: center;
  width: 5rem; }
  :local(.view-slider-item-details-header-tabs-tab):hover, :local(.view-slider-item-details-header-tabs-tab):active {
    font-family: "geograph-medium"; }
  :local(.view-slider-item-details-header-tabs-tab).desktop.extra-small, :local(.view-slider-item-details-header-tabs-tab).desktop.small, :local(.view-slider-item-details-header-tabs-tab).desktop.medium {
    margin: 0 .5rem; }

:local(.selected) {
  font-family: "geograph-medium"; }
