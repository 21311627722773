/* Auto-generated file created by Dan Tovbein 17/02/2019 at 11:38:04hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.burger-nav) {
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 999999;
  background-color: #fff;
  overflow: scroll;
  font-size: 1.5rem;
  transition: right .4s ease; }
  :local(.burger-nav) :local(.navButton) {
    padding: 2rem;
    border-bottom: solid 2px #FFBC00; }
    :local(.burger-nav) :local(.navButton):last-child {
      border-bottom: none; }

:local(.active) {
  background-image: linear-gradient(to right, #FFBC00, #FF7F00);
  color: #fff; }

:local(.show) {
  right: 0; }

:local(.hide) {
  right: -100vw; }
