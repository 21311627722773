/* Auto-generated file created by dtovbeinJC 21/11/2018 at 18:20:42hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.main-header-nav-sub-button) {
  width: 100%; }
  :local(.main-header-nav-sub-button) a {
    color: #231F20 !important;
    width: 100%;
    display: flex;
    padding: 1rem;
    transition: background-color .75s ease; }
    :local(.main-header-nav-sub-button) a:hover {
      background-color: #FF7F00; }
