/* Auto-generated file created by Dan Tovbein 14/03/2019 at 21:55:15hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-header-nav) {
  display: flex;
  align-items: center;
  width: 4rem;
  justify-content: space-between;
}

:local(.arrow-left),
:local(.arrow-right) {
  display: flex;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}

:local(.arrow-left) :local(img),
:local(.arrow-right) :local(img) {
  height: 100%;
  width: 100%;
}

:local(.arrow-right) {
  margin-left: auto;
}