/* Auto-generated file created by Dan Tovbein 17/03/2019 at 12:26:45hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.html-text) {
  display: initial; }
  :local(.html-text) p {
    font-size: .85rem;
    margin: 0;
    line-height: 20px; }
  :local(.html-text) h1 {
    font-size: 1rem; }
  :local(.html-text) ul {
    margin: 16px 0; }
    :local(.html-text) ul li {
      display: list-item;
      list-style-type: disc;
      margin-left: 1.5em; }
