/* Auto-generated file created by Dan Tovbein 20/12/2018 at 15:58:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-details-header-tabs) {
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

:local(.view-slider-item-details-header-tabs) :local(.tab) {
  display: inline-flex;
  justify-content: flex-end;
}

:local(.view-slider-item-details-header-tabs).desktop.extra-small,
:local(.view-slider-item-details-header-tabs).desktop.small,
:local(.view-slider-item-details-header-tabs).desktop.medium {
  margin-bottom: 1rem;
  padding-right: 0;
}



@media (max-width: 600px) {

  :local(.view-slider-item-details-header-tabs) {
    display: inline;
  }
}