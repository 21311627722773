/* Auto-generated file created by Dan Tovbein 17/03/2019 at 19:29:33hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.member-details) .view-slider-item-details-wrapper-side:last-child {
  width: 60%;
  padding: 1rem 3rem 2rem 3rem;
}

:local(.member-details).desktop.extra-small .view-slider-item-details-wrapper-side,
:local(.member-details).desktop.small .view-slider-item-details-wrapper-side,
:local(.member-details).desktop.medium .view-slider-item-details-wrapper-side,
:local(.member-details).mobile .view-slider-item-details-wrapper-side {
  display: flex;
}

:local(.member-details).desktop.extra-small .view-slider-item-details-wrapper-side:last-child,
:local(.member-details).desktop.small .view-slider-item-details-wrapper-side:last-child,
:local(.member-details).desktop.medium .view-slider-item-details-wrapper-side:last-child,
:local(.member-details).mobile .view-slider-item-details-wrapper-side:last-child {
  display: none;
}

:local(.member-details).desktop.extra-small :local(.wrapper-image-mobile),
:local(.member-details).desktop.small :local(.wrapper-image-mobile),
:local(.member-details).desktop.medium :local(.wrapper-image-mobile),
:local(.member-details).mobile :local(.wrapper-image-mobile) {
  display: flex;
  flex-direction: column;
  height: 50vh;
}

:local(.wrapper-image) {
  height: 18rem;
}

:local(.wrapper-image-mobile) {
  display: none;
}

:local(.extra-info) {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  color: white;
  background-color: #231F20;
  border-bottom: solid 0.25rem #FF7F00;
  font-family: "geograph-light";
  font-size: .85rem;
  margin-bottom: 1.5rem;
}

:local(.email-icon) {
  margin-right: .75rem;
  min-height: 21px;
}

:local(.fileLink) {
  background-color: #FF7F00;
  border-radius: .75rem;
  color: white;
  font-size: .825rem;
  padding: .5rem 1rem;
}