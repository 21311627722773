/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:23:00hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view) {
  transition: margin-top 0.5s ease;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 600px;
  background-position: left bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}

:local(.view).landscape {
  height: 200vh !important;
}