/* Auto-generated file created by Dan Tovbein 23/03/2019 at 18:54:51hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.footer-icon-link) {
  display: inline-flex;
  align-items: center;
  width: auto;
  margin-right: .05rem; }

:local(.wrapper-icon) {
  display: flex;
  width: 2rem;
  height: 100%;
  margin-right: .5rem; }

:local(.link) {
  display: flex;
  font-size: .9rem;
  color: #fff; }
