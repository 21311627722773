/* Auto-generated file created by Dan Tovbein 16/03/2019 at 12:18:45hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-wrapper) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
