/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:37:33hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item) {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  transition: all .2s ease;
  padding: 1.1rem; }
  :local(.view-slider-item):hover {
    background-color: rgba(255, 255, 255, 0.6); }

:local(.editorial-item) {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%; }
  :local(.editorial-item) .view-slider-item-wrapper-image {
    width: 40%;
    height: 100%; }
    :local(.editorial-item) .view-slider-item-wrapper-image .image {
      width: 100%;
      height: 100%;
      background-size: contain; }
  :local(.editorial-item) .view-slider-item-wrapper-info {
    width: 60%;
    height: 100%;
    padding-left: 1rem; }

:local(.topic), :local(.author), :local(.company) {
  font-size: .85rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: .25rem; }

:local(.footer) {
  margin-top: auto; }

:local(.btn-view-more) {
  color: #FF7F00;
  font-size: .75rem;
  font-family: "geograph-bold";
  cursor: pointer;
  letter-spacing: .04rem; }
