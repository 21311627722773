/* Auto-generated file created by dtovbeinJC 05/11/2018 at 17:41:32hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-details) {
  position: absolute;
  width: 100%; }
  :local(.view-slider-item-details) > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%; }
