/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:19:19hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-wrapper-info-description) {
  color: #231F20;
  font-family: "geograph-regular";
  font-size: .9rem;
  line-height: 120%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  overflow: hidden;
}