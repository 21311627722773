/* Auto-generated file created by dtovbeinJC 21/11/2018 at 15:20:07hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.main-header-nav-button) {
  color: #231F20;
  height: 100%;
  transition: background-color .25s ease, color .25s ease;
  font-family: "geograph-regular"; }
  :local(.main-header-nav-button) :local(a) {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 1rem;
    font-size: .9rem;
    text-align: center; }
  :local(.main-header-nav-button):hover {
    background-color: #FFBC00; }
    :local(.main-header-nav-button):hover :local(a) {
      color: white; }
    :local(.main-header-nav-button):hover :local(.submenu) {
      display: block;
      z-index: 99999; }

:local(.submenu) {
  background-image: linear-gradient(0deg, #FFBC00 0%, #FFBC00 90%, #FFBC00 100%);
  border-top: solid 0.1rem rgba(255, 255, 255, 0.85);
  display: none;
  position: absolute;
  width: 100%;
  z-index: 99999; }

:local(.legend) {
  display: inline-flex;
  height: 100%;
  align-items: center; }

:local(.active-class) {
  background-color: #FFBC00;
  color: white; }
