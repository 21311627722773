/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:37:44hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.editorial-details) .view-slider-item-details-wrapper-side:last-child {
  width: 50%;
  padding: 1rem 3rem 2rem 3rem;
}

:local(.editorial-details).desktop.extra-small .view-slider-item-details-wrapper-side:last-child,
:local(.editorial-details).desktop.small .view-slider-item-details-wrapper-side:last-child,
:local(.editorial-details).desktop.medium .view-slider-item-details-wrapper-side:last-child,
:local(.editorial-details).mobile .view-slider-item-details-wrapper-side:last-child {
  display: none;
}

:local(.editorial-details).desktop.extra-small :local(.wrapper-image-mobile),
:local(.editorial-details).desktop.small :local(.wrapper-image-mobile),
:local(.editorial-details).desktop.medium :local(.wrapper-image-mobile),
:local(.editorial-details).mobile :local(.wrapper-image-mobile) {
  display: flex;
  flex-direction: row;
  height: 15rem;
  justify-content: flex-start;
  align-items: flex-end;
}

:local(.editorial-details).desktop.extra-small :local(.wrapper-image-mobile) .image,
:local(.editorial-details).desktop.small :local(.wrapper-image-mobile) .image,
:local(.editorial-details).desktop.medium :local(.wrapper-image-mobile) .image,
:local(.editorial-details).mobile :local(.wrapper-image-mobile) .image {
  height: 100%;
  min-width: 134px;
  flex: 1 1 10rem;
  background-position: bottom left;
  background-size: contain;
  margin-right: 1rem;
}

:local(.topic) {
  font-family: "geograph-bold";
  display: block;
  width: 100%;
}

:local(.authors) {
  font-family: "geograph-bold";
}

:local(.company) {
  display: block;
  width: 100%;
}

:local(.wrapper-image) {
  height: 25rem;
  background-position: top;
}

:local(.wrapper-image) .image {
  width: 100%;
  background-position: top left;
  background-size: contain;
}

:local(.wrapper-image-mobile) {
  display: none;
}