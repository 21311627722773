/* Auto-generated file created by dtovbeinJC 05/11/2018 at 17:40:20hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.course-details) :local(.quote) {
  font-family: "geograph-regular-italic";
}

:local(.course-details) .view-slider-item-details-wrapper-side:last-child {
  width: 60%;
  padding: 1rem 3rem 2rem 3rem;
}

:local(.course-details) .html-text {
  margin: .5rem 0;
}

:local(.course-details).desktop.extra-small :local(.wrapper-image-mobile),
:local(.course-details).desktop.small :local(.wrapper-image-mobile),
:local(.course-details).desktop.medium :local(.wrapper-image-mobile),
:local(.course-details).mobile :local(.wrapper-image-mobile) {
  display: flex;
  height: 20rem;
}

:local(.course-details).desktop.extra-small .view-slider-item-details-wrapper,
:local(.course-details).desktop.small .view-slider-item-details-wrapper,
:local(.course-details).desktop.medium .view-slider-item-details-wrapper,
:local(.course-details).mobile .view-slider-item-details-wrapper {
  flex-direction: column;
}

:local(.course-details).desktop.extra-small .view-slider-item-details-wrapper-side:last-child,
:local(.course-details).desktop.small .view-slider-item-details-wrapper-side:last-child,
:local(.course-details).desktop.medium .view-slider-item-details-wrapper-side:last-child,
:local(.course-details).mobile .view-slider-item-details-wrapper-side:last-child {
  display: none;
}

:local(.course-details).desktop.extra-small :local(.extra-nav-mobile),
:local(.course-details).desktop.small :local(.extra-nav-mobile),
:local(.course-details).desktop.medium :local(.extra-nav-mobile),
:local(.course-details).mobile :local(.extra-nav-mobile) {
  display: flex;
  height: 3rem;
  margin: 0;
}

:local(.course-details).desktop.medium .view-slider-item-details-header,
:local(.course-details).mobile .view-slider-item-details-header {
  height: 100%;
}

:local(.course-details).desktop.extra-small .view-slider-item-details-header,
:local(.course-details).desktop.small .view-slider-item-details-header {
  height: 13rem;
}

:local(.wrapper-image) {
  height: 20rem;
}

:local(.extra-info) {
  margin: 1rem 0;
}

:local(.span) {
  color: #FF7F00;
  font-size: .85rem;
  display: inline-block;
  text-transform: uppercase;
}

:local(.arrow-right) {
  color: #FF7F00;
  font-size: .4rem;
  display: inline-block;
  margin-right: .5rem;
}

:local(.extra-nav),
:local(.extra-nav-mobile) {
  flex-direction: row;
  width: 100%;
}

:local(.extra-nav) .button,
:local(.extra-nav-mobile) .button {
  font-size: .9rem;
}

:local(.extra-nav) .button:first-child,
:local(.extra-nav-mobile) .button:first-child {
  margin-right: .75rem;
}

:local(.extra-nav) {
  display: flex;
}

:local(.extra-nav-mobile) {
  display: none;
}

:local(.wrapper-image-mobile) {
  display: none;
}