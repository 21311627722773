/* Auto-generated file created by Dan Tovbein 02/01/2019 at 14:35:31hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.youtube-video) {
  width: 100%;
  height: 100%;
}

:local(.wrapper) {
  height: 100%;
  width: 100%;
  background-color: black;
  overflow: hidden;
}

:local(.wrapper2) {
  top: -3rem;
  height: 130px;
  width: 320px;
  position: absolute;
}

:local(.wrapper) :local(iframe) {
  width: 100%;
  height: 100%;
}