/* Auto-generated file created by Dan Tovbein 13/02/2019 at 07:48:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-content-column) {
  display: flex;
  width: 100%;
  height: auto; }
  :local(.view-content-column) > * {
    width: 100%; }
  :local(.view-content-column).mobile, :local(.view-content-column).desktop.extra-small, :local(.view-content-column).desktop.small {
    padding-bottom: 2rem; }
    :local(.view-content-column).mobile > *, :local(.view-content-column).desktop.extra-small > *, :local(.view-content-column).desktop.small > * {
      padding-left: 0;
      padding-right: 0; }
