/* Auto-generated file created by Dan Tovbein 19/12/2018 at 17:22:39hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-details-header) {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
  padding: .5rem 0;
}

:local(.view-slider-item-details-header).mobile {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

:local(.view-slider-item-details-header).desktop.extra-small,
:local(.view-slider-item-details-header).desktop.small,
:local(.view-slider-item-details-header).desktop.medium,
:local(.view-slider-item-details-header).mobile {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: fit-content;
}

:local(.view-slider-item-details-header).desktop.extra-small :local(.wrapper-title),
:local(.view-slider-item-details-header).desktop.small :local(.wrapper-title),
:local(.view-slider-item-details-header).desktop.medium :local(.wrapper-title),
:local(.view-slider-item-details-header).mobile :local(.wrapper-title) {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-around;
  padding-top: 2rem;
}

:local(.view-slider-item-details-header).desktop.extra-small :local(.wrapper-tabs),
:local(.view-slider-item-details-header).desktop.small :local(.wrapper-tabs),
:local(.view-slider-item-details-header).desktop.medium :local(.wrapper-tabs),
:local(.view-slider-item-details-header).mobile :local(.wrapper-tabs) {
  margin-right: auto;
  margin-left: initial;
}

:local(.view-slider-item-details-header).desktop.extra-small :local(.wrapper-tabs) .view-slider-item-details-header-tabs-tab:first-child,
:local(.view-slider-item-details-header).desktop.small :local(.wrapper-tabs) .view-slider-item-details-header-tabs-tab:first-child,
:local(.view-slider-item-details-header).desktop.medium :local(.wrapper-tabs) .view-slider-item-details-header-tabs-tab:first-child,
:local(.view-slider-item-details-header).mobile :local(.wrapper-tabs) .view-slider-item-details-header-tabs-tab:first-child {
  margin-left: 0;
}

:local(.view-slider-item-details-header).desktop.extra-small :local(.miscellany),
:local(.view-slider-item-details-header).desktop.small :local(.miscellany),
:local(.view-slider-item-details-header).desktop.medium :local(.miscellany),
:local(.view-slider-item-details-header).mobile :local(.miscellany) {
  align-self: flex-start;
  margin: 1.5rem 0;
}

:local(.wrapper-title) {
  max-width: 50%;
}

:local(.title) {
  font-size: 1.6rem;
  font-family: "geograph-medium";
  color: #373435;
}

:local(.subtitle) {
  font-size: 1.4rem;
  font-family: "geograph-regular";
  color: #373435;
}

:local(.miscellany) {
  align-self: center;
  background: linear-gradient(left, #FFBC00, #FF7F00);
  display: inline-flex;
  margin-left: 1rem;
  height: .2rem;
  width: 5.75rem;
}

:local(.wrapper-tabs) {
  flex: 0 0;
  margin-left: auto;
}