/* Auto-generated file created by Dan Tovbein 15/02/2019 at 18:58:15hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.wrapper-links) {
  display: inline-flex;
  flex-direction: column;
  padding: 0 1.5rem;
  height: 100%; }
  :local(.wrapper-links):first-child {
    padding-left: 0; }
  :local(.wrapper-links):last-child {
    padding-right: 0; }
  :local(.wrapper-links) .degraded-line {
    width: 100%;
    height: .2rem;
    margin-bottom: .85rem; }

:local(.title) {
  margin-bottom: .75rem;
  font-size: 1rem;
  color: #FFBC00; }

:local(.links-list) {
  display: flex;
  flex-direction: column; }
