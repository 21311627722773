/* Auto-generated file created by Dan Tovbein 15/02/2019 at 07:14:11hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-details-wrapper-side) {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }
