/* Auto-generated file created by dtovbeinJC 04/11/2018 at 20:27:10hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.main-footer) {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 23rem;
  padding-top: 3rem;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  :local(.main-footer) :local(.links-short) {
    display: none;
    width: 100%;
    flex-direction: row;
    justify-content: space-between; }
  :local(.main-footer).desktop.medium :local(.wrapper-logo) {
    display: none; }
  :local(.main-footer).desktop.medium :local(.links) {
    width: 100%;
    display: flex;
    justify-content: space-evenly; }
  :local(.main-footer).desktop.extra-small, :local(.main-footer).desktop.small, :local(.main-footer).mobile {
    padding-left: 6vw;
    padding-right: 6vw; }
    :local(.main-footer).desktop.extra-small :local(.links-short), :local(.main-footer).desktop.small :local(.links-short), :local(.main-footer).mobile :local(.links-short) {
      display: flex; }
    :local(.main-footer).desktop.extra-small :local(.wrapper-logo), :local(.main-footer).desktop.extra-small :local(.links), :local(.main-footer).desktop.small :local(.wrapper-logo), :local(.main-footer).desktop.small :local(.links), :local(.main-footer).mobile :local(.wrapper-logo), :local(.main-footer).mobile :local(.links) {
      display: none; }

:local(.wrapper-logo) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 25%; }
  :local(.wrapper-logo) :local(.logo) {
    width: 75%; }
  :local(.wrapper-logo) :local(.description) {
    width: 100%;
    text-align: center;
    color: #fff; }

:local(.links) {
  display: flex; }

:local(.links), :local(.links-short) {
  height: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch; }

:local(.copyright) {
  flex: 1 1 100%;
  align-self: flex-end;
  display: block;
  text-align: center;
  color: #a8a8a8;
  padding: .9rem 0; }

:local(.short-nav) {
  flex-direction: row; }
  :local(.short-nav) .degraded-line {
    width: 100%;
    height: .3rem;
    margin-bottom: 1rem; }

:local(.social-nav) {
  display: flex;
  flex-direction: row;
  margin: 1rem 0 0 0; }

:local(.short-social-nav) {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem; }
  :local(.short-social-nav) img:first-child {
    margin-right: .8rem; }

:local(.nav-link) {
  color: #FFBC00;
  margin-bottom: .5rem;
  font-size: 1rem;
  width: 100%;
  display: flex;
  text-transform: uppercase; }

:local(.email-text) {
  font-size: .9rem;
  color: #fff; }
