/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:14:20hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.contact).mobile .view-content-column:last-child, :local(.contact).desktop.extra-small .view-content-column:last-child, :local(.contact).desktop.small .view-content-column:last-child, :local(.contact).desktop.medium .view-content-column:last-child {
  display: none; }

:local(.wrapper-form) {
  padding: 0 5rem 0 0; }

:local(.wrapper-picture) {
  height: 100%;
  width: 100%; }
  :local(.wrapper-picture) .image {
    background-size: 100%; }

:local(.footer) {
  width: 100%; }

:local(.footer-wrapper) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0;
  align-items: flex-start; }

:local(.asteric) {
  color: #FF7F00;
  display: inline-block;
  margin-right: .5rem; }

:local(.error-message) {
  display: block;
  margin: .5rem 0 0 0;
  color: #FF7F00; }
