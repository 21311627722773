/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:10:34hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider) {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

:local(header) {
  display: flex;
  height: auto;
  padding: .5rem 0;
  justify-content: space-between;
  width: 100%; }

:local(.content) {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%; }

:local(.wrapperPages) {
  display: flex;
  transition: left .3s ease;
  width: 100%; }

:local(.page) {
  flex: 0 0 100%;
  height: 100%; }
