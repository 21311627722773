/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:09:07hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.member-item) {
  height: 100%;
  width: 100%; }
  :local(.member-item) .view-slider-item-wrapper-image {
    width: 100%;
    height: 70%; }
    :local(.member-item) .view-slider-item-wrapper-image .image {
      width: 100%;
      height: 100%; }
  :local(.member-item) .view-slider-item-wrapper-info {
    height: 30%;
    width: 100%; }

:local(.contact) {
  color: #231F20;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  :local(.contact) :local(.info) {
    display: flex;
    flex-direction: column;
    margin: .5rem 0; }
    :local(.contact) :local(.info) .view-slider-item-wrapper-info-description {
      margin: 0; }

:local(.footer) {
  margin-top: auto; }
