/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative; }

body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: "geograph-regular";
  background-color: #EEEFF0; }

ul {
  list-style: none; }

a {
  color: black;
  text-decoration: none; }

p {
  margin: 1rem 0;
  font-size: .9rem; }
  p:first-child {
    margin-top: 0; }
  p:last-child {
    margin-bottom: 0; }

strong {
  font-family: "geograph-bold";
  font-weight: bold; }

/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.highlight {
  display: inline-block !important;
  font-family: "geograph-bold"; }

.highlight-orange {
  display: inline-block !important;
  font-family: "geograph-bold";
  font-family: "geograph-regular";
  color: #FF7F00; }

/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
@font-face {
  font-family: "geograph-regular";
  src: url("../src/media/fonts/geograph-regular.eot");
  src: local("geograph-regular"), local("geograph-regular"), url("../src/media/fonts/geograph-regular.eot?#iefix") format("embedded-opentype"), url("../src/media/fonts/geograph-regular.woff") format("woff"), url("../src/media/fonts/geograph-regular.ttf") format("truetype"), url("../src/media/fonts/geograph-regular.svg#geograph-regular") format("svg"); }

@font-face {
  font-family: "geograph-regular-italic";
  src: url("../src/media/fonts/geograph-regular-italic.eot");
  src: local("geograph-regular-italic"), local("geograph-regular-italic"), url("../src/media/fonts/geograph-regular-italic.eot?#iefix") format("embedded-opentype"), url("../src/media/fonts/geograph-regular-italic.woff") format("woff"), url("../src/media/fonts/geograph-regular-italic.ttf") format("truetype"), url("../src/media/fonts/geograph-regular-italic.svg#geograph-regular-italic") format("svg"); }

@font-face {
  font-family: "geograph-light";
  src: url("../src/media/fonts/geograph-light.eot");
  src: local("geograph-light"), local("geograph-light"), url("../src/media/fonts/geograph-light.eot?#iefix") format("embedded-opentype"), url("../src/media/fonts/geograph-light.woff") format("woff"), url("../src/media/fonts/geograph-light.ttf") format("truetype"), url("../src/media/fonts/geograph-light.svg#geograph-light") format("svg"); }

@font-face {
  font-family: "geograph-medium";
  src: url("../src/media/fonts/geograph-medium.eot");
  src: local("geograph-medium"), local("geograph-medium"), url("../src/media/fonts/geograph-medium.eot?#iefix") format("embedded-opentype"), url("../src/media/fonts/geograph-medium.woff") format("woff"), url("../src/media/fonts/geograph-medium.ttf") format("truetype"), url("../src/media/fonts/geograph-medium.svg#geograph-medium") format("svg"); }

@font-face {
  font-family: "geograph-bold";
  src: url("../src/media/fonts/geograph-bold.eot");
  src: local("geograph-bold"), local("geograph-bold"), url("../src/media/fonts/geograph-bold.eot?#iefix") format("embedded-opentype"), url("../src/media/fonts/geograph-bold.woff") format("woff"), url("../src/media/fonts/geograph-bold.ttf") format("truetype"), url("../src/media/fonts/geograph-bold.svg#geograph-bold") format("svg"); }

@font-face {
  font-family: "geograph-bold-italic";
  src: url("../src/media/fonts/geograph-bold-italic.eot");
  src: local("geograph-bold-italic"), local("geograph-bold-italic"), url("../src/media/fonts/geograph-bold-italic.eot?#iefix") format("embedded-opentype"), url("../src/media/fonts/geograph-bold-italic.woff") format("woff"), url("../src/media/fonts/geograph-bold-italic.ttf") format("truetype"), url("../src/media/fonts/geograph-bold-italic.svg#geograph-bold-italic") format("svg"); }

/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/*
  XS 1 to 480
  S 481 to 768
  M 769 to 1224
  L 1224 to infinite
*/
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */ }

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */ }

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */ }

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */ }

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */ }

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */ }

/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */ }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */ }

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */ }

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */ }

#main {
  width: 100vw; }
  #main > div {
    height: 100%;
    width: 100%; }
