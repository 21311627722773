/* Auto-generated file created by dtovbeinJC 05/11/2018 at 23:26:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.article-details) .view-slider-item-details-wrapper .view-slider-item-details-wrapper-side:first-child {
  width: 60%; }

:local(.article-details).desktop.extra-small .view-slider-item-details-wrapper-side:first-child, :local(.article-details).desktop.small .view-slider-item-details-wrapper-side:first-child, :local(.article-details).desktop.medium .view-slider-item-details-wrapper-side:first-child, :local(.article-details).mobile .view-slider-item-details-wrapper-side:first-child {
  width: 100%; }

:local(.author) {
  font-size: .9rem;
  margin: .75rem 0; }
