/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:35hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item-wrapper-info) {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word; }
