/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative; }

body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: "geograph-regular";
  background-color: #EEEFF0; }

ul {
  list-style: none; }

a {
  color: black;
  text-decoration: none; }

p {
  margin: 1rem 0;
  font-size: .9rem; }
  p:first-child {
    margin-top: 0; }
  p:last-child {
    margin-bottom: 0; }

strong {
  font-family: "geograph-bold";
  font-weight: bold; }

/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.highlight {
  display: inline-block !important;
  font-family: "geograph-bold"; }

.highlight-orange {
  display: inline-block !important;
  font-family: "geograph-bold";
  font-family: "geograph-regular";
  color: #FF7F00; }

/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
@font-face {
  font-family: "geograph-regular";
  src: url(/static/media/geograph-regular.8f5a35e1.eot);
  src: local("geograph-regular"), local("geograph-regular"), url(/static/media/geograph-regular.8f5a35e1.eot?#iefix) format("embedded-opentype"), url(/static/media/geograph-regular.8f5a35e1.woff) format("woff"), url(/static/media/geograph-regular.7d2059fb.ttf) format("truetype"), url(/static/media/geograph-regular.a8da36c0.svg#geograph-regular) format("svg"); }

@font-face {
  font-family: "geograph-regular-italic";
  src: url(/static/media/geograph-regular-italic.aef45cb5.eot);
  src: local("geograph-regular-italic"), local("geograph-regular-italic"), url(/static/media/geograph-regular-italic.aef45cb5.eot?#iefix) format("embedded-opentype"), url(/static/media/geograph-regular-italic.9c85299c.woff) format("woff"), url(/static/media/geograph-regular-italic.d525c210.ttf) format("truetype"), url(/static/media/geograph-regular-italic.f093a0bd.svg#geograph-regular-italic) format("svg"); }

@font-face {
  font-family: "geograph-light";
  src: url(/static/media/geograph-light.e174ca32.eot);
  src: local("geograph-light"), local("geograph-light"), url(/static/media/geograph-light.e174ca32.eot?#iefix) format("embedded-opentype"), url(/static/media/geograph-light.fada2517.woff) format("woff"), url(/static/media/geograph-light.5b15db61.ttf) format("truetype"), url(/static/media/geograph-light.fed271c4.svg#geograph-light) format("svg"); }

@font-face {
  font-family: "geograph-medium";
  src: url(/static/media/geograph-medium.da3b710b.eot);
  src: local("geograph-medium"), local("geograph-medium"), url(/static/media/geograph-medium.da3b710b.eot?#iefix) format("embedded-opentype"), url(/static/media/geograph-medium.5ed0bf49.woff) format("woff"), url(/static/media/geograph-medium.dd0708e0.ttf) format("truetype"), url(/static/media/geograph-medium.0e505b6a.svg#geograph-medium) format("svg"); }

@font-face {
  font-family: "geograph-bold";
  src: url(/static/media/geograph-bold.b4b35e6c.eot);
  src: local("geograph-bold"), local("geograph-bold"), url(/static/media/geograph-bold.b4b35e6c.eot?#iefix) format("embedded-opentype"), url(/static/media/geograph-bold.94f2ecee.woff) format("woff"), url(/static/media/geograph-bold.9cd8a992.ttf) format("truetype"), url(/static/media/geograph-bold.339a2d06.svg#geograph-bold) format("svg"); }

@font-face {
  font-family: "geograph-bold-italic";
  src: url(/static/media/geograph-bold-italic.e4ce6096.eot);
  src: local("geograph-bold-italic"), local("geograph-bold-italic"), url(/static/media/geograph-bold-italic.e4ce6096.eot?#iefix) format("embedded-opentype"), url(/static/media/geograph-bold-italic.2ce8061c.woff) format("woff"), url(/static/media/geograph-bold-italic.788dafec.ttf) format("truetype"), url(/static/media/geograph-bold-italic.2813bfdc.svg#geograph-bold-italic) format("svg"); }

/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/*
  XS 1 to 480
  S 481 to 768
  M 769 to 1224
  L 1224 to infinite
*/
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */ }

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */ }

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */ }

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */ }

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */ }

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */ }

/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */ }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */ }

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */ }

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */ }

#main {
  width: 100vw; }
  #main > div {
    height: 100%;
    width: 100%; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__m3qWr {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:11:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__main-header__26dd7 {
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  display: flex;
  height: 7rem;
  position: fixed;
  top: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
  border-bottom: solid 9px linear-gradient(top, #606c88, #3f4c6b);
  z-index: 99999;
}

.styles__main-header__26dd7 .styles__wrapper-logo__1wH2W {
  display: flex;
  padding-left: 2rem;
  padding-right: 1rem;
  flex: 0 0 25%;
}

.styles__main-header__26dd7 .styles__wrapper-logo__1wH2W .styles__logo__2dBoG {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.styles__main-header__26dd7 .styles__wrapper-logo__1wH2W.mobile {
  width: 80%;
  padding: 0 1rem;
}

.styles__main-header__26dd7 .styles__wrapper-logo__1wH2W.mobile .styles__logo__2dBoG {
  width: 70vw;
  height: 100%;
}

.styles__main-header__26dd7 .styles__wrapper-logo__1wH2W.mobile.landscape {
  height: 100%;
}

.styles__main-header__26dd7 .styles__wrapper-logo__1wH2W.mobile.landscape .styles__logo__2dBoG {
  width: auto;
}

.styles__main-header__26dd7 .styles__navs__3QfrB {
  display: none;
  height: 100%;
  flex: 1 1 100%;
  justify-content: flex-end;
  padding-right: 2rem;
}

.styles__main-header__26dd7 .styles__navs__3QfrB .styles__main-nav__3kz-l {
  display: flex;
  flex-direction: row;
}

.styles__main-header__26dd7 .styles__navs__3QfrB .styles__social-nav__28-Q_ {
  display: flex;
  align-items: center;
}

.styles__main-header__26dd7 .styles__navs__3QfrB .styles__social-nav__28-Q_ .button-rounded {
  background-color: #FFBC00 !important;
  margin: 0 1rem;
  text-align: center;
}

.styles__main-header__26dd7 .styles__navs__3QfrB .styles__social-nav__28-Q_ .styles__facebook-icon__3xRw_ {
  height: 2rem;
  width: 2rem;
}

.styles__facebook-icon__3xRw_ {
  margin-right: .2rem;
}

.styles__main-header__26dd7 .styles__navs__3QfrB.desktop.large {
  display: flex;
}

.styles__main-header__26dd7 .styles__btn-burger__2VZYe {
  align-self: flex-end;
  height: 1.6rem;
  width: auto;
  display: none;
  padding: 0 1.5rem 0 0;
  margin-bottom: auto;
  margin-top: auto;
}

.styles__main-header__26dd7 .styles__btn-burger__2VZYe.mobile,
.styles__main-header__26dd7 .styles__btn-burger__2VZYe.desktop.extra-small,
.styles__main-header__26dd7 .styles__btn-burger__2VZYe.desktop.small,
.styles__main-header__26dd7 .styles__btn-burger__2VZYe.desktop.medium {
  display: flex;
}

.styles__main-header__26dd7.styles__is-small__1JuKr {
  background-color: rgba(255, 255, 255, 0.98);
  height: 5rem;
}

.styles__main-header__26dd7.mobile .styles__btn-burger__2VZYe {
  height: 2rem;
}

.styles__border-bottom__2XvTv {
  position: absolute;
  bottom: -9px;
  left: 0;
  display: flex;
  height: 9px;
  width: 100%;
  align-self: flex-end;
  background: -webkit-linear-gradient(left, #FFBC00 0%, #FFBC00 70%, #FF7F00 100%);
  background: linear-gradient(90deg, #FFBC00 0%, #FFBC00 70%, #FF7F00 100%);
  z-index: -1;
}
/* Auto-generated file created by dtovbeinJC 21/11/2018 at 15:20:07hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__main-header-nav-button__lWx6P {
  color: #231F20;
  height: 100%;
  -webkit-transition: background-color .25s ease, color .25s ease;
  transition: background-color .25s ease, color .25s ease;
  font-family: "geograph-regular"; }
  .styles__main-header-nav-button__lWx6P a {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 1rem;
    font-size: .9rem;
    text-align: center; }
  .styles__main-header-nav-button__lWx6P:hover {
    background-color: #FFBC00; }
    .styles__main-header-nav-button__lWx6P:hover a {
      color: white; }
    .styles__main-header-nav-button__lWx6P:hover .styles__submenu__l9enk {
      display: block;
      z-index: 99999; }

.styles__submenu__l9enk {
  background-image: -webkit-linear-gradient(bottom, #FFBC00 0%, #FFBC00 90%, #FFBC00 100%);
  background-image: linear-gradient(0deg, #FFBC00 0%, #FFBC00 90%, #FFBC00 100%);
  border-top: solid 0.1rem rgba(255, 255, 255, 0.85);
  display: none;
  position: absolute;
  width: 100%;
  z-index: 99999; }

.styles__legend__EVUlV {
  display: inline-flex;
  height: 100%;
  align-items: center; }

.styles__active-class__2D7wb {
  background-color: #FFBC00;
  color: white; }

/* Auto-generated file created by dtovbeinJC 21/11/2018 at 18:20:42hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__main-header-nav-sub-button__30r31 {
  width: 100%; }
  .styles__main-header-nav-sub-button__30r31 a {
    color: #231F20 !important;
    width: 100%;
    display: flex;
    padding: 1rem;
    -webkit-transition: background-color .75s ease;
    transition: background-color .75s ease; }
    .styles__main-header-nav-sub-button__30r31 a:hover {
      background-color: #FF7F00; }

/* Auto-generated file created by Dan Tovbein 21/12/2018 at 12:15:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__button-rounded__1X5xQ {
  background-color: #231F20;
  border-radius: .75rem;
  color: white;
  font-size: .825rem;
  padding: .5rem 1rem; }

/* Auto-generated file created by Dan Tovbein 21/12/2018 at 12:16:09hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__button__ropqp {
  cursor: pointer;
  display: inline-flex; }

/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:14:12hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__home__yibil {
  align-items: center;
  background-attachment: fixed;
  background-blend-mode: multiply;
  background-color: rgba(39, 38, 34, 0.25);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 0 0 0 !important;
}

.styles__home__yibil .degraded-line {
  margin: 1.5rem auto 0 auto;
}

.styles__home__yibil.desktop.medium,
.styles__home__yibil.desktop.large {
  height: 150%;
}

.styles__wrapper__1vJdE {
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 70%;
  padding-top: 5%;
}

.styles__wrapper__1vJdE .styles__title__4x-5d {
  font-family: "geograph-medium";
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;
}

.styles__wrapper__1vJdE p {
  font-family: "geograph-regular-italic";
  font-size: 1rem;
  line-height: 200%;
  text-align: center;
}

.styles__wrapper__1vJdE p .styles__highlighted__2VgU3 {
  font-family: "geograph-bold-italic";
}

.styles__wrapper__1vJdE.mobile,
.styles__wrapper__1vJdE.desktop.small,
.styles__wrapper__1vJdE.desktop.extra-small {
  width: 85%;
  padding-top: 0;
  padding-bottom: 10rem;
}

.styles__wrapper__1vJdE.mobile .styles__title__4x-5d,
.styles__wrapper__1vJdE.desktop.small .styles__title__4x-5d,
.styles__wrapper__1vJdE.desktop.extra-small .styles__title__4x-5d {
  width: 100%;
  margin: 0 auto;
  font-size: 2rem;
}

.styles__footer__2EghH {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-family: "geograph-regular";
  font-size: .85rem;
  text-align: center;
  padding: 1rem 0;
  width: 100%;
}
/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:23:00hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view__ONhQF {
  -webkit-transition: margin-top 0.5s ease;
  transition: margin-top 0.5s ease;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 600px;
  background-position: left bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}

.styles__view__ONhQF.landscape {
  height: 200vh !important;
}
/* Auto-generated file created by Dan Tovbein 20/12/2018 at 11:32:25hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__degraded-line__Uw2F_ {
  background-image: -webkit-linear-gradient(left, #FFBC00, #FF7F00);
  background-image: linear-gradient(to right, #FFBC00, #FF7F00);
  height: .35rem;
  width: 3rem; }

/* Auto-generated file created by dtovbeinJC 04/11/2018 at 20:27:10hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__main-footer__29lLm {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 23rem;
  padding-top: 3rem;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .styles__main-footer__29lLm .styles__links-short__1cd8t {
    display: none;
    width: 100%;
    flex-direction: row;
    justify-content: space-between; }
  .styles__main-footer__29lLm.desktop.medium .styles__wrapper-logo__2Wemi {
    display: none; }
  .styles__main-footer__29lLm.desktop.medium .styles__links__2wTi6 {
    width: 100%;
    display: flex;
    justify-content: space-evenly; }
  .styles__main-footer__29lLm.desktop.extra-small, .styles__main-footer__29lLm.desktop.small, .styles__main-footer__29lLm.mobile {
    padding-left: 6vw;
    padding-right: 6vw; }
    .styles__main-footer__29lLm.desktop.extra-small .styles__links-short__1cd8t, .styles__main-footer__29lLm.desktop.small .styles__links-short__1cd8t, .styles__main-footer__29lLm.mobile .styles__links-short__1cd8t {
      display: flex; }
    .styles__main-footer__29lLm.desktop.extra-small .styles__wrapper-logo__2Wemi, .styles__main-footer__29lLm.desktop.extra-small .styles__links__2wTi6, .styles__main-footer__29lLm.desktop.small .styles__wrapper-logo__2Wemi, .styles__main-footer__29lLm.desktop.small .styles__links__2wTi6, .styles__main-footer__29lLm.mobile .styles__wrapper-logo__2Wemi, .styles__main-footer__29lLm.mobile .styles__links__2wTi6 {
      display: none; }

.styles__wrapper-logo__2Wemi {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 25%; }
  .styles__wrapper-logo__2Wemi .styles__logo__2gnrT {
    width: 75%; }
  .styles__wrapper-logo__2Wemi .styles__description__1weXM {
    width: 100%;
    text-align: center;
    color: #fff; }

.styles__links__2wTi6 {
  display: flex; }

.styles__links__2wTi6, .styles__links-short__1cd8t {
  height: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch; }

.styles__copyright__1vW6s {
  flex: 1 1 100%;
  align-self: flex-end;
  display: block;
  text-align: center;
  color: #a8a8a8;
  padding: .9rem 0; }

.styles__short-nav__2jGgZ {
  flex-direction: row; }
  .styles__short-nav__2jGgZ .degraded-line {
    width: 100%;
    height: .3rem;
    margin-bottom: 1rem; }

.styles__social-nav__1_aHJ {
  display: flex;
  flex-direction: row;
  margin: 1rem 0 0 0; }

.styles__short-social-nav__gyVvw {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem; }
  .styles__short-social-nav__gyVvw img:first-child {
    margin-right: .8rem; }

.styles__nav-link__3bEhi {
  color: #FFBC00;
  margin-bottom: .5rem;
  font-size: 1rem;
  width: 100%;
  display: flex;
  text-transform: uppercase; }

.styles__email-text__1eRWw {
  font-size: .9rem;
  color: #fff; }

/* Auto-generated file created by Dan Tovbein 15/02/2019 at 18:58:15hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__wrapper-links__3AbN6 {
  display: inline-flex;
  flex-direction: column;
  padding: 0 1.5rem;
  height: 100%; }
  .styles__wrapper-links__3AbN6:first-child {
    padding-left: 0; }
  .styles__wrapper-links__3AbN6:last-child {
    padding-right: 0; }
  .styles__wrapper-links__3AbN6 .degraded-line {
    width: 100%;
    height: .2rem;
    margin-bottom: .85rem; }

.styles__title__1UzlQ {
  margin-bottom: .75rem;
  font-size: 1rem;
  color: #FFBC00; }

.styles__links-list__KQ_5O {
  display: flex;
  flex-direction: column; }

/* Auto-generated file created by Dan Tovbein 15/02/2019 at 18:58:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__wrapper-link__2jUy9 a {
  color: #fff; }

.styles__title__1IS57 {
  display: inline-flex;
  width: 100%;
  color: #fff;
  font-size: .9rem;
  margin: .2rem 0; }

/* Auto-generated file created by Dan Tovbein 23/03/2019 at 18:54:51hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__footer-icon-link__325jq {
  display: inline-flex;
  align-items: center;
  width: auto;
  margin-right: .05rem; }

.styles__wrapper-icon__1iEFI {
  display: flex;
  width: 2rem;
  height: 100%;
  margin-right: .5rem; }

.styles__link__3Y0xv {
  display: flex;
  font-size: .9rem;
  color: #fff; }

/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:10:34hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider__370m0 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

header {
  display: flex;
  height: auto;
  padding: .5rem 0;
  justify-content: space-between;
  width: 100%; }

.styles__content__3uP2h {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%; }

.styles__wrapperPages__S-j5H {
  display: flex;
  -webkit-transition: left .3s ease;
  transition: left .3s ease;
  width: 100%; }

.styles__page__24oPt {
  flex: 0 0 100%;
  height: 100%; }

/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item__1Bzty {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  padding: 1.1rem; }
  .styles__view-slider-item__1Bzty:hover {
    background-color: rgba(255, 255, 255, 0.6); }

/* Auto-generated file created by Dan Tovbein 20/12/2018 at 21:51:45hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-details-wrapper__3JPd6 {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: scroll; }

/* Auto-generated file created by Dan Tovbein 16/03/2019 at 12:18:45hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-wrapper__2OF9C {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }

/* Auto-generated file created by dtovbeinJC 08/11/2018 at 18:16:03hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__title-view__3GmfO {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  height: 100%;
}

.styles__title-view__3GmfO h1 {
  color: #FF7F00;
  font-family: "geograph-bold";
  font-size: 2.5rem;
  line-height: 100%;
}

.styles__title-view__3GmfO .styles__miscellany__UuagB {
  align-self: center;
  background: -webkit-linear-gradient(left, #FFBC00, #FF7F00);
  background: linear-gradient(left, #FFBC00, #FF7F00);
  display: inline-flex;
  margin-left: 0;
  height: .2rem;
  width: 5.75rem;
}
/* Auto-generated file created by dtovbeinJC 03/12/2018 at 20:38:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__portal__2Y6aI {
  align-items: center;
  background-color: #EEEFF0;
  display: flex;
  padding: 3.2rem 10vw;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  overflow-y: scroll;
}

.styles__portal__2Y6aI .styles__wrapper__3xOkS {
  opacity: 0;
  height: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
}

.styles__wrapper__3xOkS {
  margin-top: 200px;
}

.styles__portal__2Y6aI .styles__btn-close__2G_OP {
  cursor: pointer;
  font-size: 1.05rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #FF7F00;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.styles__portal__2Y6aI .styles__btn-close__2G_OP:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.styles__portal__2Y6aI.desktop.extra-small,
.styles__portal__2Y6aI.desktop.small,
.styles__portal__2Y6aI.desktop.medium,
.styles__portal__2Y6aI.mobile {
  padding: 2rem 8vw 1rem 8vw;
  overflow: scroll;
}

.styles__portal__2Y6aI.desktop.extra-small .styles__btn-close__2G_OP,
.styles__portal__2Y6aI.desktop.small .styles__btn-close__2G_OP,
.styles__portal__2Y6aI.desktop.medium .styles__btn-close__2G_OP,
.styles__portal__2Y6aI.mobile .styles__btn-close__2G_OP {
  top: 0;
}
/* Auto-generated file created by Dan Tovbein 13/03/2019 at 21:53:58hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__preloader__rnYmc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

.styles__preloader-wrapper__1hvXW {
  display: inline-flex;
  flex-direction: column;
  align-items: center; }

.styles__preloader-image__1bqQe {
  width: 3.5rem;
  height: 3.5rem; }

.styles__preloader-text__U9VXs {
  margin: 1rem 0;
  font-size: .9rem;
  color: #231F20; }

/* Auto-generated file created by Dan Tovbein 14/03/2019 at 21:55:15hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-header-nav__3ZQ8F {
  display: flex;
  align-items: center;
  width: 4rem;
  justify-content: space-between;
}

.styles__arrow-left__1f0iC,
.styles__arrow-right__iCqH0 {
  display: flex;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}

.styles__arrow-left__1f0iC img,
.styles__arrow-right__iCqH0 img {
  height: 100%;
  width: 100%;
}

.styles__arrow-right__iCqH0 {
  margin-left: auto;
}
/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:37:33hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item__2S67Q {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  padding: 1.1rem; }
  .styles__view-slider-item__2S67Q:hover {
    background-color: rgba(255, 255, 255, 0.6); }

.styles__editorial-item__23zoM {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%; }
  .styles__editorial-item__23zoM .view-slider-item-wrapper-image {
    width: 40%;
    height: 100%; }
    .styles__editorial-item__23zoM .view-slider-item-wrapper-image .image {
      width: 100%;
      height: 100%;
      background-size: contain; }
  .styles__editorial-item__23zoM .view-slider-item-wrapper-info {
    width: 60%;
    height: 100%;
    padding-left: 1rem; }

.styles__topic__ZyMtQ, .styles__author__e2n_7, .styles__company__1ikC7 {
  font-size: .85rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin-bottom: .25rem; }

.styles__footer__feK9x {
  margin-top: auto; }

.styles__btn-view-more__13s61 {
  color: #FF7F00;
  font-size: .75rem;
  font-family: "geograph-bold";
  cursor: pointer;
  letter-spacing: .04rem; }

/* Auto-generated file created by Dan Tovbein 13/02/2019 at 19:11:16hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__image__1AiOX {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:21:03hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-wrapper-image__3N97b {
  width: 100%;
  height: 1rem; }

/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:35hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-wrapper-info__1GGW9 {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word; }

/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:46hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-wrapper-info-title__qKBNI {
  color: #231F20;
  font-family: "geograph-bold";
  font-size: 1rem;
  line-height: 120%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin: .25rem 0; }

/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:37:44hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__editorial-details__T_Mvk .view-slider-item-details-wrapper-side:last-child {
  width: 50%;
  padding: 1rem 3rem 2rem 3rem;
}

.styles__editorial-details__T_Mvk.desktop.extra-small .view-slider-item-details-wrapper-side:last-child,
.styles__editorial-details__T_Mvk.desktop.small .view-slider-item-details-wrapper-side:last-child,
.styles__editorial-details__T_Mvk.desktop.medium .view-slider-item-details-wrapper-side:last-child,
.styles__editorial-details__T_Mvk.mobile .view-slider-item-details-wrapper-side:last-child {
  display: none;
}

.styles__editorial-details__T_Mvk.desktop.extra-small .styles__wrapper-image-mobile__YmkYd,
.styles__editorial-details__T_Mvk.desktop.small .styles__wrapper-image-mobile__YmkYd,
.styles__editorial-details__T_Mvk.desktop.medium .styles__wrapper-image-mobile__YmkYd,
.styles__editorial-details__T_Mvk.mobile .styles__wrapper-image-mobile__YmkYd {
  display: flex;
  flex-direction: row;
  height: 15rem;
  justify-content: flex-start;
  align-items: flex-end;
}

.styles__editorial-details__T_Mvk.desktop.extra-small .styles__wrapper-image-mobile__YmkYd .image,
.styles__editorial-details__T_Mvk.desktop.small .styles__wrapper-image-mobile__YmkYd .image,
.styles__editorial-details__T_Mvk.desktop.medium .styles__wrapper-image-mobile__YmkYd .image,
.styles__editorial-details__T_Mvk.mobile .styles__wrapper-image-mobile__YmkYd .image {
  height: 100%;
  min-width: 134px;
  flex: 1 1 10rem;
  background-position: bottom left;
  background-size: contain;
  margin-right: 1rem;
}

.styles__topic__3TaFV {
  font-family: "geograph-bold";
  display: block;
  width: 100%;
}

.styles__authors__25slN {
  font-family: "geograph-bold";
}

.styles__company__oPV4- {
  display: block;
  width: 100%;
}

.styles__wrapper-image__ULPwV {
  height: 25rem;
  background-position: top;
}

.styles__wrapper-image__ULPwV .image {
  width: 100%;
  background-position: top left;
  background-size: contain;
}

.styles__wrapper-image-mobile__YmkYd {
  display: none;
}
/* Auto-generated file created by dtovbeinJC 05/11/2018 at 17:41:32hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-details__3b2XI {
  position: absolute;
  width: 100%; }
  .styles__view-slider-item-details__3b2XI > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%; }

/* Auto-generated file created by Dan Tovbein 19/12/2018 at 17:22:39hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-details-header___t6vV {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
  padding: .5rem 0;
}

.styles__view-slider-item-details-header___t6vV.mobile {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.styles__view-slider-item-details-header___t6vV.desktop.extra-small,
.styles__view-slider-item-details-header___t6vV.desktop.small,
.styles__view-slider-item-details-header___t6vV.desktop.medium,
.styles__view-slider-item-details-header___t6vV.mobile {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.styles__view-slider-item-details-header___t6vV.desktop.extra-small .styles__wrapper-title__3t5Mp,
.styles__view-slider-item-details-header___t6vV.desktop.small .styles__wrapper-title__3t5Mp,
.styles__view-slider-item-details-header___t6vV.desktop.medium .styles__wrapper-title__3t5Mp,
.styles__view-slider-item-details-header___t6vV.mobile .styles__wrapper-title__3t5Mp {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-around;
  padding-top: 2rem;
}

.styles__view-slider-item-details-header___t6vV.desktop.extra-small .styles__wrapper-tabs__3H1s9,
.styles__view-slider-item-details-header___t6vV.desktop.small .styles__wrapper-tabs__3H1s9,
.styles__view-slider-item-details-header___t6vV.desktop.medium .styles__wrapper-tabs__3H1s9,
.styles__view-slider-item-details-header___t6vV.mobile .styles__wrapper-tabs__3H1s9 {
  margin-right: auto;
  margin-left: 0;
  margin-left: initial;
}

.styles__view-slider-item-details-header___t6vV.desktop.extra-small .styles__wrapper-tabs__3H1s9 .view-slider-item-details-header-tabs-tab:first-child,
.styles__view-slider-item-details-header___t6vV.desktop.small .styles__wrapper-tabs__3H1s9 .view-slider-item-details-header-tabs-tab:first-child,
.styles__view-slider-item-details-header___t6vV.desktop.medium .styles__wrapper-tabs__3H1s9 .view-slider-item-details-header-tabs-tab:first-child,
.styles__view-slider-item-details-header___t6vV.mobile .styles__wrapper-tabs__3H1s9 .view-slider-item-details-header-tabs-tab:first-child {
  margin-left: 0;
}

.styles__view-slider-item-details-header___t6vV.desktop.extra-small .styles__miscellany__28SPf,
.styles__view-slider-item-details-header___t6vV.desktop.small .styles__miscellany__28SPf,
.styles__view-slider-item-details-header___t6vV.desktop.medium .styles__miscellany__28SPf,
.styles__view-slider-item-details-header___t6vV.mobile .styles__miscellany__28SPf {
  align-self: flex-start;
  margin: 1.5rem 0;
}

.styles__wrapper-title__3t5Mp {
  max-width: 50%;
}

.styles__title__10gLV {
  font-size: 1.6rem;
  font-family: "geograph-medium";
  color: #373435;
}

.styles__subtitle__1cVYE {
  font-size: 1.4rem;
  font-family: "geograph-regular";
  color: #373435;
}

.styles__miscellany__28SPf {
  align-self: center;
  background: -webkit-linear-gradient(left, #FFBC00, #FF7F00);
  background: linear-gradient(left, #FFBC00, #FF7F00);
  display: inline-flex;
  margin-left: 1rem;
  height: .2rem;
  width: 5.75rem;
}

.styles__wrapper-tabs__3H1s9 {
  flex: 0 0;
  margin-left: auto;
}
/* Auto-generated file created by Dan Tovbein 20/12/2018 at 15:58:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-details-header-tabs__1MCPp {
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.styles__view-slider-item-details-header-tabs__1MCPp .styles__tab__1mPM_ {
  display: inline-flex;
  justify-content: flex-end;
}

.styles__view-slider-item-details-header-tabs__1MCPp.desktop.extra-small,
.styles__view-slider-item-details-header-tabs__1MCPp.desktop.small,
.styles__view-slider-item-details-header-tabs__1MCPp.desktop.medium {
  margin-bottom: 1rem;
  padding-right: 0;
}



@media (max-width: 600px) {

  .styles__view-slider-item-details-header-tabs__1MCPp {
    display: inline;
  }
}
/* Auto-generated file created by Dan Tovbein 20/12/2018 at 16:04:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-details-header-tabs-tab__180nB {
  cursor: pointer;
  display: inline-flex;
  font-size: .9rem;
  margin: 0 1rem;
  justify-content: center;
  width: 5rem; }
  .styles__view-slider-item-details-header-tabs-tab__180nB:hover, .styles__view-slider-item-details-header-tabs-tab__180nB:active {
    font-family: "geograph-medium"; }
  .styles__view-slider-item-details-header-tabs-tab__180nB.desktop.extra-small, .styles__view-slider-item-details-header-tabs-tab__180nB.desktop.small, .styles__view-slider-item-details-header-tabs-tab__180nB.desktop.medium {
    margin: 0 .5rem; }

.styles__selected__3e_Bg {
  font-family: "geograph-medium"; }

/* Auto-generated file created by Dan Tovbein 15/02/2019 at 07:14:11hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-details-wrapper-side__10iON {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

/* Auto-generated file created by Dan Tovbein 17/03/2019 at 12:26:45hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__html-text__2Aq_h {
  display: inline;
  display: initial; }
  .styles__html-text__2Aq_h p {
    font-size: .85rem;
    margin: 0;
    line-height: 20px; }
  .styles__html-text__2Aq_h h1 {
    font-size: 1rem; }
  .styles__html-text__2Aq_h ul {
    margin: 16px 0; }
    .styles__html-text__2Aq_h ul li {
      display: list-item;
      list-style-type: disc;
      margin-left: 1.5em; }

/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:32:54hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-content__3TXkQ {
  display: flex;
  padding: 3.2rem 10vw;
  width: 100%;
  height: 100%;
  flex-direction: column; }
  .styles__view-content__3TXkQ .title-view {
    width: 100%;
    padding: 3rem 0;
    height: auto; }
  .styles__view-content__3TXkQ .styles__wrapper__2B6LS {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; }
  .styles__view-content__3TXkQ.mobile, .styles__view-content__3TXkQ.desktop.extra-small, .styles__view-content__3TXkQ.desktop.small, .styles__view-content__3TXkQ.desktop.medium {
    padding-left: 5vw !important;
    padding-right: 5vw !important; }
    .styles__view-content__3TXkQ.mobile .title-view, .styles__view-content__3TXkQ.desktop.extra-small .title-view, .styles__view-content__3TXkQ.desktop.small .title-view, .styles__view-content__3TXkQ.desktop.medium .title-view {
      padding: 1rem 0; }
    .styles__view-content__3TXkQ.mobile .styles__wrapper__2B6LS, .styles__view-content__3TXkQ.desktop.extra-small .styles__wrapper__2B6LS, .styles__view-content__3TXkQ.desktop.small .styles__wrapper__2B6LS, .styles__view-content__3TXkQ.desktop.medium .styles__wrapper__2B6LS {
      flex-direction: column; }

/* Auto-generated file created by dtovbeinJC 05/11/2018 at 16:51:54hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item__1JyII {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  padding: 1.1rem;
}

.styles__view-slider-item__1JyII:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.styles__course-item__3tNUN {
  width: 100%;
  height: 100%;
}

.styles__course-item__3tNUN .view-slider-item-wrapper-image {
  height: 40%;
}

.styles__course-item__3tNUN .view-slider-item-wrapper-image .image {
  width: 100%;
  height: 100%;
}

.styles__course-item__3tNUN .view-slider-item-wrapper-info {
  height: 60%;
}

.styles__course-item__3tNUN .view-slider-item-wrapper-info-date {
  margin-top: .4rem;
  color: #FF7F00;
}

.styles__footer__9LeMK {
  margin-top: auto;
}

.styles__btnVerMas__3_f0p {
  background-color: #FF7F00;
  border-radius: .75rem;
  color: white;
  font-size: .825rem;
  padding: .5rem 1rem;
  cursor: pointer;
}
/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-wrapper-info-date__1l9Vl {
  color: #231F20;
  font-family: "geograph-regular";
  font-size: .85rem;
  line-height: 120%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin: 0 0 .4rem 0; }

/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:19:19hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item-wrapper-info-description__Thq1V {
  color: #231F20;
  font-family: "geograph-regular";
  font-size: .9rem;
  line-height: 120%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  overflow: hidden;
}
/* Auto-generated file created by dtovbeinJC 05/11/2018 at 17:40:20hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__course-details__2Aknt .styles__quote__2mTf_ {
  font-family: "geograph-regular-italic";
}

.styles__course-details__2Aknt .view-slider-item-details-wrapper-side:last-child {
  width: 60%;
  padding: 1rem 3rem 2rem 3rem;
}

.styles__course-details__2Aknt .html-text {
  margin: .5rem 0;
}

.styles__course-details__2Aknt.desktop.extra-small .styles__wrapper-image-mobile__XkLjN,
.styles__course-details__2Aknt.desktop.small .styles__wrapper-image-mobile__XkLjN,
.styles__course-details__2Aknt.desktop.medium .styles__wrapper-image-mobile__XkLjN,
.styles__course-details__2Aknt.mobile .styles__wrapper-image-mobile__XkLjN {
  display: flex;
  height: 20rem;
}

.styles__course-details__2Aknt.desktop.extra-small .view-slider-item-details-wrapper,
.styles__course-details__2Aknt.desktop.small .view-slider-item-details-wrapper,
.styles__course-details__2Aknt.desktop.medium .view-slider-item-details-wrapper,
.styles__course-details__2Aknt.mobile .view-slider-item-details-wrapper {
  flex-direction: column;
}

.styles__course-details__2Aknt.desktop.extra-small .view-slider-item-details-wrapper-side:last-child,
.styles__course-details__2Aknt.desktop.small .view-slider-item-details-wrapper-side:last-child,
.styles__course-details__2Aknt.desktop.medium .view-slider-item-details-wrapper-side:last-child,
.styles__course-details__2Aknt.mobile .view-slider-item-details-wrapper-side:last-child {
  display: none;
}

.styles__course-details__2Aknt.desktop.extra-small .styles__extra-nav-mobile__1Ejt0,
.styles__course-details__2Aknt.desktop.small .styles__extra-nav-mobile__1Ejt0,
.styles__course-details__2Aknt.desktop.medium .styles__extra-nav-mobile__1Ejt0,
.styles__course-details__2Aknt.mobile .styles__extra-nav-mobile__1Ejt0 {
  display: flex;
  height: 3rem;
  margin: 1rem 0;
}

.styles__course-details__2Aknt.desktop.medium .view-slider-item-details-header,
.styles__course-details__2Aknt.mobile .view-slider-item-details-header {
  height: 100%;
}

.styles__course-details__2Aknt.desktop.extra-small .view-slider-item-details-header,
.styles__course-details__2Aknt.desktop.small .view-slider-item-details-header {
  height: 13rem;
}

.styles__wrapper-image__2qd1H {
  height: 20rem;
}

.styles__extra-info__3WhoO {
  margin: 1rem 0;
}

.styles__span__RioWQ {
  color: #FF7F00;
  font-size: .85rem;
  display: inline-block;
  text-transform: uppercase;
}

.styles__arrow-right__Psh2C {
  color: #FF7F00;
  font-size: .4rem;
  display: inline-block;
  margin-right: .5rem;
}

.styles__extra-nav__1zZkT,
.styles__extra-nav-mobile__1Ejt0 {
  flex-direction: row;
  width: 100%;
}

.styles__extra-nav__1zZkT .button,
.styles__extra-nav-mobile__1Ejt0 .button {
  font-size: .9rem;
}

.styles__extra-nav__1zZkT .button:first-child,
.styles__extra-nav-mobile__1Ejt0 .button:first-child {
  margin-right: .75rem;
}

.styles__extra-nav__1zZkT {
  display: flex;
}

.styles__extra-nav-mobile__1Ejt0 {
  display: none;
}

.styles__wrapper-image-mobile__XkLjN {
  display: none;
}
/* Auto-generated file created by Dan Tovbein 21/12/2018 at 14:51:49hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__tab-section__15PJH {
  border-top: solid 1px #231F20;
  margin-top: 1rem;
  padding-top: 1rem; }
  .styles__tab-section__15PJH .styles__plain-text__3XrB1 {
    color: #373435; }
    .styles__tab-section__15PJH .styles__plain-text__3XrB1 ul {
      padding-top: 1rem;
      padding-left: 1rem;
      list-style-type: disc; }
      .styles__tab-section__15PJH .styles__plain-text__3XrB1 ul li {
        font-size: .85rem;
        padding-bottom: .4rem; }

/* Auto-generated file created by Dan Tovbein 20/12/2018 at 23:03:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__tab-section-title__2UTz- {
  color: #373435;
  font-family: "geograph-regular";
  font-size: 1.05rem;
  margin-bottom: 1rem; }

/* Auto-generated file created by dtovbeinJC 05/11/2018 at 23:26:43hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item__PciEc {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  padding: 1.1rem; }
  .styles__view-slider-item__PciEc:hover {
    background-color: rgba(255, 255, 255, 0.6); }

.styles__article-item__3svuS {
  width: 100%;
  height: 100%; }

.styles__author__3Ih3t {
  font-size: .85rem; }

.styles__footer__JJaop {
  margin-top: auto; }

/* Auto-generated file created by dtovbeinJC 05/11/2018 at 23:26:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__article-details__c5Zul .view-slider-item-details-wrapper .view-slider-item-details-wrapper-side:first-child {
  width: 60%; }

.styles__article-details__c5Zul.desktop.extra-small .view-slider-item-details-wrapper-side:first-child, .styles__article-details__c5Zul.desktop.small .view-slider-item-details-wrapper-side:first-child, .styles__article-details__c5Zul.desktop.medium .view-slider-item-details-wrapper-side:first-child, .styles__article-details__c5Zul.mobile .view-slider-item-details-wrapper-side:first-child {
  width: 100%; }

.styles__author__294Ad {
  font-size: .9rem;
  margin: .75rem 0; }

/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:36:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-slider-item__3XV6m {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  padding: 1.1rem; }
  .styles__view-slider-item__3XV6m:hover {
    background-color: rgba(255, 255, 255, 0.6); }

.styles__video-item__xJj51 {
  width: 100%;
  height: 100%; }
  .styles__video-item__xJj51 .view-slider-item-wrapper-info {
    height: 35%;
    padding: .5rem 0; }
    .styles__video-item__xJj51 .view-slider-item-wrapper-info .view-slider-item-wrapper-info-description {
      font-size: .85rem; }

.styles__wrapper-video__2dwsX {
  height: 65%; }

/* Auto-generated file created by Dan Tovbein 02/01/2019 at 14:35:31hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__youtube-video__pmlsw {
  width: 100%;
  height: 100%;
}

.styles__wrapper__2b1Hd {
  height: 100%;
  width: 100%;
  background-color: black;
  overflow: hidden;
}

.styles__wrapper2__1L4Mm {
  top: -3rem;
  height: 130px;
  width: 320px;
  position: absolute;
}

.styles__wrapper__2b1Hd iframe {
  width: 100%;
  height: 100%;
}
/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:14:20hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__contact__1d3oM.mobile .view-content-column:last-child, .styles__contact__1d3oM.desktop.extra-small .view-content-column:last-child, .styles__contact__1d3oM.desktop.small .view-content-column:last-child, .styles__contact__1d3oM.desktop.medium .view-content-column:last-child {
  display: none; }

.styles__wrapper-form___EXMg {
  padding: 0 5rem 0 0; }

.styles__wrapper-picture__1HyUk {
  height: 100%;
  width: 100%; }
  .styles__wrapper-picture__1HyUk .image {
    background-size: 100%; }

.styles__footer__14L03 {
  width: 100%; }

.styles__footer-wrapper__2yCn3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0;
  align-items: flex-start; }

.styles__asteric__2Citu {
  color: #FF7F00;
  display: inline-block;
  margin-right: .5rem; }

.styles__error-message__1PFUR {
  display: block;
  margin: .5rem 0 0 0;
  color: #FF7F00; }

/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:21:47hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__form-item__fx3i4 {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .styles__form-item__fx3i4 label, .styles__form-item__fx3i4 input, .styles__form-item__fx3i4 textarea {
    display: inline-block;
    width: 100%;
    border: none;
    font-size: .85rem; }
  .styles__form-item__fx3i4 label {
    padding-top: .75rem;
    padding-bottom: .75rem;
    text-transform: uppercase; }
  .styles__form-item__fx3i4 input {
    height: 3rem; }
  .styles__form-item__fx3i4 textarea {
    height: 6rem; }
  .styles__form-item__fx3i4 input, .styles__form-item__fx3i4 textarea {
    padding: .5rem;
    background-color: #dedede; }

.styles__required-icon__1N-hO {
  color: #FFBC00; }

/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:22:05hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__form__3XIDO {
  display: inline;
  display: initial; }

/* Don't forget to remove this class */

/* Auto-generated file created by Dan Tovbein 13/02/2019 at 07:48:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__view-content-column__1L9BF {
  display: flex;
  width: 100%;
  height: auto; }
  .styles__view-content-column__1L9BF > * {
    width: 100%; }
  .styles__view-content-column__1L9BF.mobile, .styles__view-content-column__1L9BF.desktop.extra-small, .styles__view-content-column__1L9BF.desktop.small {
    padding-bottom: 2rem; }
    .styles__view-content-column__1L9BF.mobile > *, .styles__view-content-column__1L9BF.desktop.extra-small > *, .styles__view-content-column__1L9BF.desktop.small > * {
      padding-left: 0;
      padding-right: 0; }

/* Auto-generated file created by Dan Tovbein 17/03/2019 at 19:29:33hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__member-details__Bum50 .view-slider-item-details-wrapper-side:last-child {
  width: 60%;
  padding: 1rem 3rem 2rem 3rem;
}

.styles__member-details__Bum50.desktop.extra-small .view-slider-item-details-wrapper-side,
.styles__member-details__Bum50.desktop.small .view-slider-item-details-wrapper-side,
.styles__member-details__Bum50.desktop.medium .view-slider-item-details-wrapper-side,
.styles__member-details__Bum50.mobile .view-slider-item-details-wrapper-side {
  display: flex;
}

.styles__member-details__Bum50.desktop.extra-small .view-slider-item-details-wrapper-side:last-child,
.styles__member-details__Bum50.desktop.small .view-slider-item-details-wrapper-side:last-child,
.styles__member-details__Bum50.desktop.medium .view-slider-item-details-wrapper-side:last-child,
.styles__member-details__Bum50.mobile .view-slider-item-details-wrapper-side:last-child {
  display: none;
}

.styles__member-details__Bum50.desktop.extra-small .styles__wrapper-image-mobile__3vWmO,
.styles__member-details__Bum50.desktop.small .styles__wrapper-image-mobile__3vWmO,
.styles__member-details__Bum50.desktop.medium .styles__wrapper-image-mobile__3vWmO,
.styles__member-details__Bum50.mobile .styles__wrapper-image-mobile__3vWmO {
  display: flex;
  flex-direction: column;
  height: 50vh;
}

.styles__wrapper-image__3zAo_ {
  height: 18rem;
}

.styles__wrapper-image-mobile__3vWmO {
  display: none;
}

.styles__extra-info__2IBs_ {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  color: white;
  background-color: #231F20;
  border-bottom: solid 0.25rem #FF7F00;
  font-family: "geograph-light";
  font-size: .85rem;
  margin-bottom: 1.5rem;
}

.styles__email-icon__2-Ofj {
  margin-right: .75rem;
  min-height: 21px;
}

.styles__fileLink__2q5Z7 {
  background-color: #FF7F00;
  border-radius: .75rem;
  color: white;
  font-size: .825rem;
  padding: .5rem 1rem;
}
/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:09:07hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__member-item__vTiQq {
  height: 100%;
  width: 100%; }
  .styles__member-item__vTiQq .view-slider-item-wrapper-image {
    width: 100%;
    height: 70%; }
    .styles__member-item__vTiQq .view-slider-item-wrapper-image .image {
      width: 100%;
      height: 100%; }
  .styles__member-item__vTiQq .view-slider-item-wrapper-info {
    height: 30%;
    width: 100%; }

.styles__contact__1ogk_ {
  color: #231F20;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .styles__contact__1ogk_ .styles__info__tnshW {
    display: flex;
    flex-direction: column;
    margin: .5rem 0; }
    .styles__contact__1ogk_ .styles__info__tnshW .view-slider-item-wrapper-info-description {
      margin: 0; }

.styles__footer__2BpYG {
  margin-top: auto; }

/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:15:57hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__wrapper-info__er1Na {
  padding: 0 4rem 0 0;
}

.styles__wrapper-info__er1Na p {
  font-size: .85rem;
}

.styles__wrapper-picture__2p9MM {
  height: 100%;
  width: 100%;
}

.styles__wrapper-picture__2p9MM .image {
  width: 100vh;
  background-size: 100% auto;
  background-position: top left;
}

.styles__wrapper-picture__2p9MM.desktop.extra-small,
.styles__wrapper-picture__2p9MM.desktop.small,
.styles__wrapper-picture__2p9MM.mobile,
.styles__wrapper-picture__2p9MM.desktop.medium {
  margin: 3rem 0 0 0;
}

.styles__wrapper-picture__2p9MM.desktop.extra-small .image,
.styles__wrapper-picture__2p9MM.desktop.small .image,
.styles__wrapper-picture__2p9MM.mobile .image,
.styles__wrapper-picture__2p9MM.desktop.medium .image {
  width: auto;
}

.styles__wrapper-picture__2p9MM.desktop.extra-small,
.styles__wrapper-picture__2p9MM.desktop.small,
.styles__wrapper-picture__2p9MM.mobile {
  height: 25vh;
}

.styles__wrapper-picture__2p9MM.desktop.medium {
  height: 50vh;
}
/* Auto-generated file created by Dan Tovbein 17/02/2019 at 11:38:04hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__burger-nav__Bx5Qx {
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 999999;
  background-color: #fff;
  overflow: scroll;
  font-size: 1.5rem;
  -webkit-transition: right .4s ease;
  transition: right .4s ease; }
  .styles__burger-nav__Bx5Qx .styles__navButton__36DHY {
    padding: 2rem;
    border-bottom: solid 2px #FFBC00; }
    .styles__burger-nav__Bx5Qx .styles__navButton__36DHY:last-child {
      border-bottom: none; }

.styles__active__JP43r {
  background-image: -webkit-linear-gradient(left, #FFBC00, #FF7F00);
  background-image: linear-gradient(to right, #FFBC00, #FF7F00);
  color: #fff; }

.styles__show__1Ncpt {
  right: 0; }

.styles__hide__35ymp {
  right: -100vw; }

/* Auto-generated file created by Dan Tovbein 23/03/2019 at 20:15:05hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__arrow-scroll-top__3rSBt {
  height: 4rem;
  position: fixed;
  z-index: 1;
  -webkit-animation: moveScrollTopArrow 2s infinite;
          animation: moveScrollTopArrow 2s infinite;
  -webkit-transition: right 1s ease;
  transition: right 1s ease;
  right: 0; }

@-webkit-keyframes moveScrollTopArrow {
  0% {
    top: 70vh; }
  50% {
    top: 70.5vh; }
  100% {
    top: 70vh; } }

@keyframes moveScrollTopArrow {
  0% {
    top: 70vh; }
  50% {
    top: 70.5vh; }
  100% {
    top: 70vh; } }

/* Auto-generated file created by dtovbeinJC 03/12/2018 at 20:38:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__portal__3JtNp {
  align-items: center;
  background-color: #EEEFF0;
  display: flex;
  padding: 3.2rem 10vw;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  overflow-y: scroll;
}

.styles__portal__3JtNp .styles__wrapper__2_Sti {
  opacity: 0;
  height: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
}

.styles__portal__3JtNp .styles__btn-close__3i9U8 {
  cursor: pointer;
  font-size: 1.05rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #FF7F00;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.styles__portal__3JtNp .styles__btn-close__3i9U8:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.styles__portal__3JtNp.desktop.extra-small,
.styles__portal__3JtNp.desktop.small,
.styles__portal__3JtNp.desktop.medium,
.styles__portal__3JtNp.mobile {
  padding: 2rem 8vw 1rem 8vw;
  overflow: scroll;
}

.styles__portal__3JtNp.desktop.extra-small .styles__btn-close__3i9U8,
.styles__portal__3JtNp.desktop.small .styles__btn-close__3i9U8,
.styles__portal__3JtNp.desktop.medium .styles__btn-close__3i9U8,
.styles__portal__3JtNp.mobile .styles__btn-close__3i9U8 {
  top: 0;
}
/* Auto-generated file created by dtovbeinJC 03/12/2018 at 20:38:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__portal__VOX0W {
  align-items: center;
  background-color: #EEEFF0;
  display: flex;
  padding: 3.2rem 10vw;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  overflow-y: scroll;
}

.styles__portal__VOX0W .styles__wrapper__3BxCF {
  opacity: 0;
  height: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
}

.styles__portal__VOX0W .styles__btn-close__1Hz9u {
  cursor: pointer;
  font-size: 1.05rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #FF7F00;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.styles__portal__VOX0W .styles__btn-close__1Hz9u:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.styles__portal__VOX0W.desktop.extra-small,
.styles__portal__VOX0W.desktop.small,
.styles__portal__VOX0W.desktop.medium,
.styles__portal__VOX0W.mobile {
  padding: 2rem 8vw 1rem 8vw;
  overflow: scroll;
}

.styles__portal__VOX0W.desktop.extra-small .styles__btn-close__1Hz9u,
.styles__portal__VOX0W.desktop.small .styles__btn-close__1Hz9u,
.styles__portal__VOX0W.desktop.medium .styles__btn-close__1Hz9u,
.styles__portal__VOX0W.mobile .styles__btn-close__1Hz9u {
  top: 0;
}
/* Auto-generated file created by dtovbeinJC 03/12/2018 at 20:38:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__portal__2Ykv0 {
  align-items: center;
  background-color: #EEEFF0;
  display: flex;
  padding: 3.2rem 10vw;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  overflow-y: scroll;
}

.styles__portal__2Ykv0 .styles__wrapper__1rkCU {
  opacity: 0;
  height: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
}

.styles__portal__2Ykv0 .styles__btn-close__2lMgt {
  cursor: pointer;
  font-size: 1.05rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #FF7F00;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.styles__portal__2Ykv0 .styles__btn-close__2lMgt:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.styles__portal__2Ykv0.desktop.extra-small,
.styles__portal__2Ykv0.desktop.small,
.styles__portal__2Ykv0.desktop.medium,
.styles__portal__2Ykv0.mobile {
  padding: 2rem 8vw 1rem 8vw;
  overflow: scroll;
}

.styles__portal__2Ykv0.desktop.extra-small .styles__btn-close__2lMgt,
.styles__portal__2Ykv0.desktop.small .styles__btn-close__2lMgt,
.styles__portal__2Ykv0.desktop.medium .styles__btn-close__2lMgt,
.styles__portal__2Ykv0.mobile .styles__btn-close__2lMgt {
  top: 0;
}
/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__17H3i {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__1qPcj {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__3UZqj {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__39aoA {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__BOdIx {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__2nfFu {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__UTZrk {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__2uXD1 {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__app__1r0Cq {
  position: relative;
  width: 100vw; }

/* Auto-generated file created by dtovbeinJC 05/11/2018 at 17:40:20hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__course-details__3oXhu .styles__quote__3l6X- {
  font-family: "geograph-regular-italic";
}

.styles__course-details__3oXhu .view-slider-item-details-wrapper-side:last-child {
  width: 60%;
  padding: 1rem 3rem 2rem 3rem;
}

.styles__course-details__3oXhu .html-text {
  margin: .5rem 0;
}

.styles__course-details__3oXhu.desktop.extra-small .styles__wrapper-image-mobile__2DZsh,
.styles__course-details__3oXhu.desktop.small .styles__wrapper-image-mobile__2DZsh,
.styles__course-details__3oXhu.desktop.medium .styles__wrapper-image-mobile__2DZsh,
.styles__course-details__3oXhu.mobile .styles__wrapper-image-mobile__2DZsh {
  display: flex;
  height: 20rem;
}

.styles__course-details__3oXhu.desktop.extra-small .view-slider-item-details-wrapper,
.styles__course-details__3oXhu.desktop.small .view-slider-item-details-wrapper,
.styles__course-details__3oXhu.desktop.medium .view-slider-item-details-wrapper,
.styles__course-details__3oXhu.mobile .view-slider-item-details-wrapper {
  flex-direction: column;
}

.styles__course-details__3oXhu.desktop.extra-small .view-slider-item-details-wrapper-side:last-child,
.styles__course-details__3oXhu.desktop.small .view-slider-item-details-wrapper-side:last-child,
.styles__course-details__3oXhu.desktop.medium .view-slider-item-details-wrapper-side:last-child,
.styles__course-details__3oXhu.mobile .view-slider-item-details-wrapper-side:last-child {
  display: none;
}

.styles__course-details__3oXhu.desktop.extra-small .styles__extra-nav-mobile__umli6,
.styles__course-details__3oXhu.desktop.small .styles__extra-nav-mobile__umli6,
.styles__course-details__3oXhu.desktop.medium .styles__extra-nav-mobile__umli6,
.styles__course-details__3oXhu.mobile .styles__extra-nav-mobile__umli6 {
  display: flex;
  height: 3rem;
  margin: 0;
}

.styles__course-details__3oXhu.desktop.medium .view-slider-item-details-header,
.styles__course-details__3oXhu.mobile .view-slider-item-details-header {
  height: 100%;
}

.styles__course-details__3oXhu.desktop.extra-small .view-slider-item-details-header,
.styles__course-details__3oXhu.desktop.small .view-slider-item-details-header {
  height: 13rem;
}

.styles__wrapper-image__3mr95 {
  height: 20rem;
}

.styles__extra-info__z8lcA {
  margin: 1rem 0;
}

.styles__span__32Bgx {
  color: #FF7F00;
  font-size: .85rem;
  display: inline-block;
  text-transform: uppercase;
}

.styles__arrow-right__2zz8r {
  color: #FF7F00;
  font-size: .4rem;
  display: inline-block;
  margin-right: .5rem;
}

.styles__extra-nav__3y1Qs,
.styles__extra-nav-mobile__umli6 {
  flex-direction: row;
  width: 100%;
}

.styles__extra-nav__3y1Qs .button,
.styles__extra-nav-mobile__umli6 .button {
  font-size: .9rem;
}

.styles__extra-nav__3y1Qs .button:first-child,
.styles__extra-nav-mobile__umli6 .button:first-child {
  margin-right: .75rem;
}

.styles__extra-nav__3y1Qs {
  display: flex;
}

.styles__extra-nav-mobile__umli6 {
  display: none;
}

.styles__wrapper-image-mobile__2DZsh {
  display: none;
}
/* Auto-generated file created by dtovbeinJC 03/12/2018 at 20:38:27hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
.styles__portal__3oZb0 {
  align-items: center;
  background-color: #EEEFF0;
  display: flex;
  padding: 3.2rem 10vw;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  overflow-y: scroll;
}

.styles__portal__3oZb0 .styles__wrapper__2iNqJ {
  opacity: 0;
  height: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
}

.styles__portal__3oZb0 .styles__btn-close__3C5kL {
  cursor: pointer;
  font-size: 1.05rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #FF7F00;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.styles__portal__3oZb0 .styles__btn-close__3C5kL:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.styles__portal__3oZb0.desktop.extra-small,
.styles__portal__3oZb0.desktop.small,
.styles__portal__3oZb0.desktop.medium,
.styles__portal__3oZb0.mobile {
  padding: 2rem 8vw 1rem 8vw;
  overflow: scroll;
}

.styles__portal__3oZb0.desktop.extra-small .styles__btn-close__3C5kL,
.styles__portal__3oZb0.desktop.small .styles__btn-close__3C5kL,
.styles__portal__3oZb0.desktop.medium .styles__btn-close__3C5kL,
.styles__portal__3oZb0.mobile .styles__btn-close__3C5kL {
  top: 0;
}
