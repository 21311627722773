/* Auto-generated file created by Dan Tovbein 20/12/2018 at 23:03:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.tab-section-title) {
  color: #373435;
  font-family: "geograph-regular";
  font-size: 1.05rem;
  margin-bottom: 1rem; }
