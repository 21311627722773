/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:36:55hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.view-slider-item) {
  display: inline-flex;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  transition: all .2s ease;
  padding: 1.1rem; }
  :local(.view-slider-item):hover {
    background-color: rgba(255, 255, 255, 0.6); }

:local(.video-item) {
  width: 100%;
  height: 100%; }
  :local(.video-item) .view-slider-item-wrapper-info {
    height: 35%;
    padding: .5rem 0; }
    :local(.video-item) .view-slider-item-wrapper-info .view-slider-item-wrapper-info-description {
      font-size: .85rem; }

:local(.wrapper-video) {
  height: 65%; }
