/* Auto-generated file created by Dan Tovbein 13/03/2019 at 21:53:58hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.preloader) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

:local(.preloader-wrapper) {
  display: inline-flex;
  flex-direction: column;
  align-items: center; }

:local(.preloader-image) {
  width: 3.5rem;
  height: 3.5rem; }

:local(.preloader-text) {
  margin: 1rem 0;
  font-size: .9rem;
  color: #231F20; }
