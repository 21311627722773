/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:15:57hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.wrapper-info) {
  padding: 0 4rem 0 0;
}

:local(.wrapper-info) p {
  font-size: .85rem;
}

:local(.wrapper-picture) {
  height: 100%;
  width: 100%;
}

:local(.wrapper-picture) .image {
  width: 100vh;
  background-size: 100% auto;
  background-position: top left;
}

:local(.wrapper-picture).desktop.extra-small,
:local(.wrapper-picture).desktop.small,
:local(.wrapper-picture).mobile,
:local(.wrapper-picture).desktop.medium {
  margin: 3rem 0 0 0;
}

:local(.wrapper-picture).desktop.extra-small .image,
:local(.wrapper-picture).desktop.small .image,
:local(.wrapper-picture).mobile .image,
:local(.wrapper-picture).desktop.medium .image {
  width: auto;
}

:local(.wrapper-picture).desktop.extra-small,
:local(.wrapper-picture).desktop.small,
:local(.wrapper-picture).mobile {
  height: 25vh;
}

:local(.wrapper-picture).desktop.medium {
  height: 50vh;
}