/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:21:47hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.form-item) {
  width: 100%;
  display: flex;
  flex-direction: column; }
  :local(.form-item) label, :local(.form-item) input, :local(.form-item) textarea {
    display: inline-block;
    width: 100%;
    border: none;
    font-size: .85rem; }
  :local(.form-item) label {
    padding-top: .75rem;
    padding-bottom: .75rem;
    text-transform: uppercase; }
  :local(.form-item) input {
    height: 3rem; }
  :local(.form-item) textarea {
    height: 6rem; }
  :local(.form-item) input, :local(.form-item) textarea {
    padding: .5rem;
    background-color: #dedede; }

:local(.required-icon) {
  color: #FFBC00; }
