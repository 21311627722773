/* Auto-generated file created by Dan Tovbein 21/12/2018 at 12:15:53hs */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
/* Auto-generated file created by Dan Tovbein 06/11/2019 at 22:24:29hs */
/* READ ONLY FILE, NOT EDITABLE! */
:local(.button-rounded) {
  background-color: #231F20;
  border-radius: .75rem;
  color: white;
  font-size: .825rem;
  padding: .5rem 1rem; }
